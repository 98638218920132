<script lang="ts" setup>
import type { SearchResponse, Hit } from 'algoliasearch/lite';
import type { SearchQuery } from '~~/types';
import type { AlgoliaRegioloket, AlgoliaFacets, AlgoliaOpleiding, AlgoliaRelatedOnderwijsinstelling, AlgoliaOnderwijsInstelling } from '~~/types/algolia';


const searchHistoryKey = 'index'

definePageMeta({
  name: 'navigator',
})

const { icons } = useConstants()

const crumbs = [{
  icon: icons.home,
  to: 'https://onderwijsloket.com'
}, {
  label: 'Navigator',
  icon: icons.navigator,
  to: '/'
}]


useSeoMeta({
  title: 'Onderwijsnavigator',
  description: "Zoek en ontdek lerarenopleidingen, scholen, onderwijsinstellingen en regioloketten bij jou in de buurt.",
})


const { isGeoSearch, searchRadiusAsInteger, currentPositionAsLatLngString, currentLocation, getAlgoliaGeoLocationRequestOptions, clearLocation } = useGeolocation()
const { query, setQuery, resetQuery } = useQuery()

const { searchMultiple } = useAlgoliaSearch() // pass your index name as param
const { page: opleidingenPage, hitsPerPage: opleidingenHitsPerPage, resetPagination: resetOpleidingenPagination } = usePagination({ hitsPerPage: 20, hitsPerPageKey: 'opleidingenperpage', pageKey: 'opleidingenpage' })
const { page: regiolokettenPage, hitsPerPage: regiolokettenHitsPerPage, resetPagination: resetRegiolokettenPagination } = usePagination({ hitsPerPage: 20, hitsPerPageKey: 'regiolokettenperpage', pageKey: 'regiolokettenpage' })
const { page: onderwijsinstellingenPage, hitsPerPage: onderwijsinstellingenHitsPerPage, resetPagination: resetOnderwijsinstellingenPagination } = usePagination({ hitsPerPage: 20, hitsPerPageKey: 'onderwijsinstellingenperpage', pageKey: 'onderwijsinstellingenpage' })
const opleidingenFacets: Ref<AlgoliaFacets | null> = useState('index_opleidingen_facets', () => null)
const regiolokettenFacets: Ref<AlgoliaFacets | null> = useState('index_regioloketten_facets', () => null)
const onderwijsinstellingenFacets: Ref<AlgoliaFacets | null> = useState('index_onderwijsinstellingen_facets', () => null)


const onderwijsinstellingenData: Ref<SearchResponse<AlgoliaOnderwijsInstelling> | null> = useState('index_onderwijsinstellingen_data', () => null)

const { 
  scrollToResults, 
  selectedSectors, 
  getSectorFacetFilter, 
  selectedContentTypes,
  addOrRemoveContentType,
  selectedSchoolvakken,
  getSchoolvakkenFacetFilter,
  selectedOnderwijsvormen,
  getOnderwijsvormenFacetFilter,
  selectedBevoegdheden,
  getBevoegdhedenFacetFilter,
  selectedInstellingTypes,
  getInstellingTypesFacetFilter,
  resetNavigatorParams
} = useNavigatorSearch() 

const selectedContentTypeIds = computed(() => selectedContentTypes.value.map(type => type.id))


const useContentTypes = computed(() => {
  return {
    regioloket: !selectedContentTypes.value.length || selectedContentTypes.value.findIndex((type) => type.id === 'regioloket') > -1,
    opleiding: !selectedContentTypes.value.length || selectedContentTypes.value.findIndex((type) => type.id === 'opleiding') > -1,
    onderwijsinstelling: !selectedContentTypes.value.length || selectedContentTypes.value.findIndex((type) => type.id === 'onderwijsinstelling') > -1,
  }
})

const regiolokettenAsSearchList = computed(() => {
  return !useContentTypes.value.opleiding && useContentTypes.value.regioloket
})

const opleidingenAsSearchList = computed(() => {
  return useContentTypes.value.opleiding
})

const onderwijsinstellingenAsSearchList = computed(() => {
  return Object.values(useContentTypes.value).filter(val => !!val).length === 1 && useContentTypes.value.onderwijsinstelling
})

const defaultFacetOptions = {
  hitsPerPage: 1000,
  query: '',
  facets: ['*'],
  attributesToRetrieve: [],
  attributesToHighlight: []
}


const { data, error, status, refresh } = await useAsyncData(async () => {
  const sectorFilters = getSectorFacetFilter()
  const schoolvakkenFilters = getSchoolvakkenFacetFilter()
  const onderwijsvormenFilters = getOnderwijsvormenFacetFilter()
  const bevoegdhedenFilters = getBevoegdhedenFacetFilter()
  const instellingsTypesFilters = getInstellingTypesFacetFilter()

  const requests: any[] = []
  
  let loketIndex = useContentTypes.value.regioloket ? requests.length : null
  if (useContentTypes.value.regioloket) {
    requests.push(
      { 
        indexName: 'regioloketten',
        query: query.value,
        facets: ['*'],
        page: regiolokettenPage.value - 1 || 0,
        hitsPerPage: regiolokettenAsSearchList.value ? regiolokettenHitsPerPage.value : 1000,
        facetFilters: [
          sectorFilters
        ],
        attributesToHighlight: [],
        ...getAlgoliaGeoLocationRequestOptions({ filterByRadius: true })
      },
    )
  }

  let opleidingIndex = useContentTypes.value.opleiding ? requests.length : null
  let opleidingExhaustiveIndex = useContentTypes.value.opleiding ? requests.length + 1 : null
  if (useContentTypes.value.opleiding) {
    requests.push(
      { 
        indexName: 'opleidingen',
        query: query.value,
        page: opleidingenPage.value - 1,
        hitsPerPage: opleidingenHitsPerPage.value,
        facets: ['*'],
        facetFilters: [
          schoolvakkenFilters,
          onderwijsvormenFilters,
          bevoegdhedenFilters
        ],
        attributesToHighlight: [],
        ...getAlgoliaGeoLocationRequestOptions({ filterByRadius: true })
      },
      { 
        indexName: 'opleidingen',
        query: query.value,
        hitsPerPage: 9999,
        facets: ['*'],
        facetFilters: [
          schoolvakkenFilters,
          onderwijsvormenFilters,
          bevoegdhedenFilters
        ],
        attributesToRetrieve: ['name', '_geoloc', 'id', 'onderwijsinstellingen', 'slug', 'bevoegdheden'],
        attributesToHighlight: [],
        ...getAlgoliaGeoLocationRequestOptions({ filterByRadius: true })
      },
    )
  }

  // Only fetch onderwijsinstellingen if its used as a search list
  let onderwijsinstellingenIndex = onderwijsinstellingenAsSearchList.value ? requests.length : null
  let onderwijsinstellingenExhaustiveIndex = onderwijsinstellingenAsSearchList.value ? requests.length + 1 : null
  if (onderwijsinstellingenAsSearchList.value) {
    requests.push(
      { 
        indexName: 'onderwijsinstellingen',
        query: query.value,
        page: onderwijsinstellingenPage.value - 1,
        hitsPerPage: onderwijsinstellingenHitsPerPage.value,
        facets: ['*'],
        facetFilters: [
          instellingsTypesFilters,
          onderwijsvormenFilters,
          bevoegdhedenFilters
        ],
        attributesToHighlight: [],
        ...getAlgoliaGeoLocationRequestOptions({ filterByRadius: true })
      },
      { 
        indexName: 'onderwijsinstellingen',
        query: query.value,
        hitsPerPage: 9999,
        facets: ['*'],
        facetFilters: [
          instellingsTypesFilters,
          onderwijsvormenFilters,
          bevoegdhedenFilters
        ],
        attributesToRetrieve: ['name', '_geoloc', 'id', 'opleidingen.name', 'slug', 'bevoegdheden'],
        attributesToHighlight: [],
        ...getAlgoliaGeoLocationRequestOptions({ filterByRadius: true })
      },
    )
  }



  let opleidingFacetIndex = !opleidingenFacets.value ? requests.length : null
  if (!opleidingenFacets.value) {
    requests.push( { 
      indexName: 'opleidingen',
      ...defaultFacetOptions
    })
  }

  let regioloketFacetIndex = !regiolokettenFacets.value ? requests.length : null
  if (!regiolokettenFacets.value) {
    requests.push( { 
      indexName: 'regioloketten',
      ...defaultFacetOptions
    })
  }

  let instellingFacetIndex = !onderwijsinstellingenFacets.value || !onderwijsinstellingenData.value ? requests.length : null
  if (!onderwijsinstellingenFacets.value || !onderwijsinstellingenData.value) {
    requests.push( { 
      indexName: 'onderwijsinstellingen',
      ...defaultFacetOptions,
      attributesToRetrieve: ['*'] // Override for defaultFacetOptions
    })
  }

  const result = await searchMultiple<AlgoliaRegioloket | AlgoliaOpleiding | AlgoliaOnderwijsInstelling>({
      requests
  })

  if (!opleidingenFacets.value && typeof opleidingFacetIndex === 'number' && result.results[opleidingFacetIndex]) {
    opleidingenFacets.value = result.results[opleidingFacetIndex].facets as AlgoliaFacets
  }
  if (!regiolokettenFacets.value && typeof regioloketFacetIndex === 'number' && result.results[regioloketFacetIndex]) {
    regiolokettenFacets.value = result.results[regioloketFacetIndex].facets as AlgoliaFacets
  }
  if (!onderwijsinstellingenFacets.value && typeof instellingFacetIndex === 'number' && result.results[instellingFacetIndex]) {
    onderwijsinstellingenFacets.value = result.results[instellingFacetIndex].facets as AlgoliaFacets
  }
  if (!onderwijsinstellingenData.value && typeof instellingFacetIndex === 'number' && result.results[instellingFacetIndex]) {
    onderwijsinstellingenData.value = result.results[instellingFacetIndex] as SearchResponse<AlgoliaOnderwijsInstelling>
  }

  let onderwijsinstellingen: null | Hit<AlgoliaOnderwijsInstelling>[] | SearchResponse<AlgoliaOnderwijsInstelling> = null

  // Get a list of unique onderwijsinstellingen for the opleidingen results
  if (!onderwijsinstellingenAsSearchList.value && useContentTypes.value.onderwijsinstelling && !!onderwijsinstellingenData.value && typeof opleidingExhaustiveIndex === 'number' &&  result.results[opleidingExhaustiveIndex]) {
    const onderwijsinstellingMap = new Map<number, AlgoliaRelatedOnderwijsinstelling>();

    for (const item of result.results[opleidingExhaustiveIndex].hits) {
      (item as Hit<AlgoliaOpleiding>).onderwijsinstellingen.forEach((onderwijsinstelling) => {
        if (!onderwijsinstellingMap.has(onderwijsinstelling.id)) {
          onderwijsinstellingMap.set(onderwijsinstelling.id, onderwijsinstelling);
        }
      });
    }
    // Step 1: Collect all onderwijsinstelling IDs from hits in their appearing order
    const orderedIds: number[] = [];
    for (const hit of result.results[opleidingExhaustiveIndex].hits as AlgoliaOpleiding[]) {
      for (const instelling of hit.onderwijsinstellingen) {
        if (!orderedIds.includes(instelling.id)) {
          orderedIds.push(instelling.id);
        }
      }
    }
    // Step 2: Create a Set of these IDs for quick lookup
    const idsSet = new Set(orderedIds);

    // Step 3: Filter onderwijsinstellingenData based on the collected IDs
    onderwijsinstellingen = onderwijsinstellingenData.value.hits.filter((instelling) =>
      idsSet.has(instelling.id)
    );

    // Step 4: Sort filtered onderwijsinstellingen to match the order in orderedIds
    onderwijsinstellingen.sort(
      (a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id)
    );

    
  } else if (onderwijsinstellingenAsSearchList.value) {
    onderwijsinstellingen = typeof onderwijsinstellingenIndex === 'number' ? result.results[onderwijsinstellingenIndex] as SearchResponse<AlgoliaOnderwijsInstelling> : null
  }

  return {
    results:{
      regioloketten: typeof loketIndex === 'number' ? result.results[loketIndex] as SearchResponse<AlgoliaRegioloket> : null,
      opleidingen: typeof opleidingIndex === 'number' ? result.results[opleidingIndex] as SearchResponse<AlgoliaOpleiding> : null,
      onderwijsinstellingen
    },
    map: {
      regioloketten: typeof loketIndex === 'number' ? result.results[loketIndex] as SearchResponse<AlgoliaRegioloket> : null,
      opleidingen: typeof opleidingExhaustiveIndex === 'number' ? result.results[opleidingExhaustiveIndex] as SearchResponse<AlgoliaOpleiding> : null,
      onderwijsinstellingen: typeof onderwijsinstellingenExhaustiveIndex === 'number' ? result.results[onderwijsinstellingenExhaustiveIndex] as SearchResponse<AlgoliaOnderwijsInstelling> : null
    }
  }
})

const isLoading = computed(() => status.value === 'idle' || status.value === 'pending')

type ResultMeta = {
  regioloketten?: Omit<SearchResponse<AlgoliaRegioloket>, 'hits'>
  opleidingen?: Omit<SearchResponse<AlgoliaRegioloket>, 'hits'>
  onderwijsinstellingen?: Omit<SearchResponse<AlgoliaOnderwijsInstelling>, 'hits'>
}

const metaData = computed(() => {
  let meta: ResultMeta = {}
  if (!data.value?.results) return meta
  
  if (data.value.results.regioloketten) {
    const { hits, ...rest } = data.value.results.regioloketten
    meta.regioloketten = rest
  }
  if (data.value.results.opleidingen) {
    const { hits, ...rest } = data.value.results.opleidingen
    meta.opleidingen = rest
  }

  if (onderwijsinstellingenAsSearchList.value) {
    const { hits, ...rest } = data.value.results.onderwijsinstellingen as SearchResponse<AlgoliaOnderwijsInstelling>
    meta.onderwijsinstellingen = rest
  }

  return meta
})

// These two computes are specifically for TS reasons
const instellingenForListCard = computed(() => data.value?.results?.onderwijsinstellingen as Hit<AlgoliaOnderwijsInstelling>[])
const instellingenForSearchList = computed(() => !!data.value?.results?.onderwijsinstellingen && 'hits' in data.value.results.onderwijsinstellingen ? (data.value.results.onderwijsinstellingen as SearchResponse<AlgoliaOnderwijsInstelling>).hits : [])

const contentCount = computed(() => ({
  regioloket: data.value?.results.regioloketten?.nbHits || 0,
  opleiding: data.value?.results.opleidingen?.nbHits || 0,
  onderwijsinstelling:  Array.isArray(data.value?.results.onderwijsinstellingen) ? data.value.results.onderwijsinstellingen.length : data.value?.results.onderwijsinstellingen?.nbHits || 0,
  school: 0,
}))

const { isEqual } = useLodash()
// Set custom watcher for useAsyncData, that disables watch sources when a new route is loading (since some watch sources are bound to url state)
watch([
  query,
  () => isGeoSearch.value ? searchRadiusAsInteger.value : null,
  currentPositionAsLatLngString,
  selectedContentTypes,
  opleidingenPage,
  opleidingenHitsPerPage,
  regiolokettenPage,
  regiolokettenHitsPerPage,
  onderwijsinstellingenPage,
  onderwijsinstellingenHitsPerPage,
  selectedSectors,
  selectedSchoolvakken,
  selectedOnderwijsvormen,
  selectedBevoegdheden
], (newVal, oldVal) => {
  // TODO Reset pagination upon content type change. However, this will break other url state updates
  if (!isEqual(newVal, oldVal)) refresh()
})


const totalHits = computed(() => {
  let count = 0
  Object.entries(metaData.value).forEach(([key,value]) => count += value.nbHits)
  return count
})

const schoolvakken = computed(() => {
  return !!opleidingenFacets.value?.schoolvakken ? Object.keys(opleidingenFacets.value.schoolvakken) : []
})

const instellingTypes = computed(() => {
  return !!onderwijsinstellingenFacets.value?.soort ? Object.keys(onderwijsinstellingenFacets.value.soort) : []
})


watch(data, () => {
  scrollToResults()
  const store = useSearchHistoryStore()
  store.addSearchQueryToHistory({
    key: searchHistoryKey,
    value: {
      query: query.value,
      withFilters: !!selectedSectors.value.length || !!selectedContentTypes.value.length || !!selectedBevoegdheden.value.length || !!selectedOnderwijsvormen.value.length || !!selectedSchoolvakken.value.length,
      address: currentLocation.value ?? undefined,
      hits: totalHits.value
    }
  })
})

const { removeAllQueryKeys } = useParamHelpers()

const reset = () => {
  clearLocation()
  resetQuery()
  resetOpleidingenPagination()
  resetRegiolokettenPagination()
  resetOnderwijsinstellingenPagination()
  resetNavigatorParams()
  removeAllQueryKeys()
}


import type { ContentType } from '~~/types';
const handleContentCountSelect = (id: ContentType["id"]) => {
  selectedContentTypes.value =  addOrRemoveContentType(id, selectedContentTypes.value)
}

const { grid } = useResponsive()
const { isMounted } = useUiState()
const { baseSuggestions } = useAppConfig().settings.navigator

const { contentTypes, bevoegdheden, onderwijsvormen, sectors } = useConstants()

function joinWithAnd(items: string[]): string {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0] as string;
  if (items.length === 2) return items.join(' en ');

  const allButLast = items.slice(0, -1).join(', ');
  const last = items[items.length - 1];
  
  return `${allButLast} en ${last}`;
}

const heading = computed(() => {
  if (!selectedContentTypes.value.length) return joinWithAnd(contentTypes.map(c => c.label.plural))
  return joinWithAnd(contentTypes.filter(c => selectedContentTypes.value.map(type => type.id).includes(c.id)).map(c => c.label.plural))
})

const opleidingenColumns = [
  {
    label: 'Opleiding',
    key: 'name',
  },
  {
    label: 'Plaats',
    key: 'plaats',
  },
  {
    label: 'Bevoegdheden',
    key: 'bevoegdheden',
  },
  {
    label: 'Onderwijsvormen',
    key: 'onderwijsvormen',
  },
  {
    label: 'Schoolvakken',
    key: 'schoolvakken',
  },
  {
    label: 'Opleider',
    key: 'onderwijsinstelling',
  }
]

const regiolokettenColumns = [
  {
    key: 'name',
    label: 'Regioloket'
  },
  {
    key: 'sectoren',
    label: 'Sectoren'
  },
  {
    key: 'regio',
    label: 'Regio'
  },
  {
    key: 'phone',
    label: 'Telefoon'
  },
  {
    key: 'email',
    label: 'Email'
  }
]

const onderwijsinstellingenColumns = [
    {
      key: 'name',
      label: 'Onderwijsinstelling'
    },
    {
      key: 'plaats',
      label: 'Plaats'
    },
    {
      key: 'onderwijsvormen',
      label: 'Onderwijsvormen'
    },
    {
      key: 'opleidingen',
      label: 'Aantal opleidingen'
    },
  ]

//FIXME opleidingen will break google map

</script>


<template>
  <NuxtLayout name="navigator" :crumbs="crumbs">
    <h1 class="text-gray-800 dark:text-gray-100 xl:text-6xl relative">Zoek en ontdek <GradientText>{{ heading }}</GradientText> bij jou in de buurt</h1>
    <Search 
      v-model="query" 
      sticky 
      placeholder="wat wil je zoeken?"
      :search-settings="{
        title: 'Mijn zoekprofiel',
        useSearchHistory: true,
        searchHistoryKey,
        useReset: true
      }"
      :debounce="400"
      @reset="reset"
    >
      <template #tools>
        <Address use-toast minimal />
      </template>
      <template #settings>
        <Location with-map with-description class="mt-2" />
        <Select 
          id="content"
          v-model="selectedContentTypes"
          disable-count
          label="Content soorten"
          placeholder="Kies een of meer soorten"
          description="Welke soorten informatie ben je in geïnteresseerd?"
          :options="contentTypes"
          :string-select="false"
        />
        <Select 
          v-if="useContentTypes.regioloket"
          id="sectoren"
          v-model="selectedSectors"
          disable-count
          label="Sectoren"
          placeholder="Kies een of meer sectoren"
          description="Welke onderwijssectoren ben je in geïnteresseerd? Met deze selectie filter je regioloketten."
          :options="sectors"
          :string-select="false"
        />
        <Select 
          v-if="useContentTypes.opleiding"
          id="bevoegdheden"
          v-model="selectedBevoegdheden"
          disable-count
          label="Bevoegdheden"
          placeholder="Kies een of meer bevoegdheden"
          description="Welke onderwijsbevoegdheden ben je in geïnteresseerd? Met deze selectie filter je opleidingen."
          :options="bevoegdheden"
          :string-select="false"
        />
        <Select 
          v-if="useContentTypes.opleiding"
          id="onderwijsvormen"
          v-model="selectedOnderwijsvormen"
          disable-count
          label="Onderwijsvormen"
          placeholder="Kies een of meer vormen"
          description="Welke onderwijsbevoegdheden ben je in geïnteresseerd? Met deze selectie filter je opleidingen."
          :options="onderwijsvormen"
          :string-select="false"
        />
        <Select 
          v-if="useContentTypes.opleiding"
          id="schoolvakken"
          v-model="selectedSchoolvakken"
          disable-count
          label="Schoolvakken"
          placeholder="Kies een of meer vakken"
          description="Voor welke schoolvakken zou je je bevoegdheid willen behalen? Met deze selectie filter je opleidingen."
          :options="schoolvakken"
          searchable
          searchable-placeholder="Zoek naar schoolvakken"
          string-select
        />

        <Select 
          id="instellingtypes"
          v-model="selectedInstellingTypes"
          label="Soorten onderwijsinstellingen"
          placeholder="Kies een of meer soorten"
          description="Bij wat voor soort instelling wil je een opleiding volgen? Met deze selectie filter je onderwijsinstellingen."
          :options="instellingTypes"
          string-select
          disable-count
        />
      </template>
    </Search>
    <History 
      :search-history-key="searchHistoryKey"
      :current-query="query"
      :base-suggestions="baseSuggestions"
      @set-query="setQuery"
    />

    <div class="relative pt-6 md:pt-12">
      <ContentTypeTabs 
        class="md:mb-12 py-12"
        :opleidingen-facets="opleidingenFacets?.bevoegdheden"
        :regioloketten-facets="regiolokettenFacets?.sectoren"
        :onderwijsinstellingen-facets="onderwijsinstellingenFacets?.soort"
      />
      <ContentCount 
        :data="contentCount"
        :use-content-types="useContentTypes"
        :selected-content-types="selectedContentTypeIds"
        use-filters
        use-share
        @select="handleContentCountSelect"
      />
      <section
        class="grid grid-cols-1 gap-6 lg:gap-10 py-6 lg:grid-cols-5"
      >
        <SearchResultsContainer>
          <InstellingenListCard 
            v-if="(!!data?.results?.onderwijsinstellingen?.length || isLoading) && useContentTypes.onderwijsinstelling && !onderwijsinstellingenAsSearchList" 
            :data="instellingenForListCard" 
            :loading="isLoading" 
            :error="error"
            :is-geo-search="isGeoSearch" 
          />
          <LazySearchResultsTable
            v-if="regiolokettenAsSearchList"
            :data="data?.results?.regioloketten?.hits"
            type="regioloketten"
            :loading="isLoading"
            :error="error"
            :columns="regiolokettenColumns"

            v-model:page="regiolokettenPage"
            v-model:hits-per-page="regiolokettenHitsPerPage"
            :total-hits="metaData.regioloketten?.nbHits"
            use-pagination
            @refresh="refresh"
          >
            <template #name-data="{row}">
              <UPopover 
                mode="hover" 
                :open-delay="500" 
                :disabled="isMounted && !grid.sm"
              >
                <RowHead
                  :name="row.name"
                  :image="row.imageUrl"
                  :to="'/regioloketten/' + row.slug"
                  heading-class="max-w-none sm:max-w-80"
                  class="mt-1"
                  :icon="icons.regioloket"
                />
                <template #panel>
                  <RegioloketPreview 
                    :data="row"
                  />
                </template>
              </UPopover>
            </template>

            <template #sectoren-data="{ row }">
              <Sectoren :data="row.sectoren" />
            </template>
            <template #regio-data="{ row }">
              <Plaats :label=" row.regio || row.plaats" :distance="row._rankingInfo?.geoDistance" />
            </template>
            <template #phone-data="{ row }">
              <ContactMethod type="phone" :data="row.phone" />
            </template>
            <template #email-data="{ row }">
              <ContactMethod type="email" :data="row.email" />
            </template>      
          </LazySearchResultsTable>
          <LazySearchResultsTable
            v-if="onderwijsinstellingenAsSearchList"
            
            :data="instellingenForSearchList"
            type="onderwijsinstellingen"
            :loading="isLoading"
            :error="error"
            :columns="onderwijsinstellingenColumns"

            v-model:page="onderwijsinstellingenPage"
            v-model:hits-per-page="onderwijsinstellingenHitsPerPage"
            :total-hits="metaData.onderwijsinstellingen?.nbHits"
            use-pagination
            @refresh="refresh"
          >
            <template #name-data="{row}">
              <UPopover 
                mode="hover" 
                :open-delay="500" 
                :disabled="isMounted && !grid.sm"
              >
              <RowHead
                :name="row.name"
                :image="row.imageUrl"
                :to="'/onderwijsinstellingen/' + row.slug"
                :icon="icons.onderwijsinstelling"
                class="mt-1"
              />

              <template #panel>
                <OpleiderPreview 
                  :data="row"
                />
              </template>
              </UPopover>
            </template>

            <template #plaats-data="{row}">
              <Plaats :label="row.plaats" :distance="row._rankingInfo?.geoDistance" />
            </template>

            <template #onderwijsvormen-data="{row}">
              <Onderwijsvormen :data="row.onderwijsvormen" />
            </template>
            <template #opleidingen-data="{row}">
              <OpleidingenCount :count="row.opleidingen.length" />
            </template>
          </LazySearchResultsTable>
          <LazySearchResultsTable
            v-else-if="opleidingenAsSearchList"
            :data="data?.results?.opleidingen?.hits"
            type="opleidingen"
            :loading="isLoading"
            :error="error"
            :columns="opleidingenColumns"

            v-model:page="opleidingenPage"
            v-model:hits-per-page="opleidingenHitsPerPage"
            :total-hits="metaData.opleidingen?.nbHits"
            use-pagination
            
            @refresh="refresh"
          >
            <template #name-data="{row}">
              <UPopover 
                mode="hover" 
                :open-delay="500" 
                :disabled="isMounted && !grid.sm"
              >
                <RowHead
                  :name="row.name"
                  :image="row.imageUrl"
                  :to="'/opleidingen/' + row.slug"
                  heading-class="max-w-none sm:max-w-80"
                  class="mt-1"
                  :icon="icons.opleiding"
                />
                <template #panel>
                  <OpleidingPreview 
                    :data="row"
                  />
                </template>
              </UPopover>
            </template>

            <template #plaats-data="{row}">
              <Plaats :label="row.plaats" :distance="row._rankingInfo?.geoDistance" />
            </template>

            <template #bevoegdheden-data="{row}">
              <Bevoegdheden :data="row.bevoegdheden" />
            </template>

            <template #onderwijsvormen-data="{row}">
              <Onderwijsvormen :data="row.onderwijsvormen" />
            </template>

            <template #schoolvakken-data="{row}">
              <Schoolvakken :data="row.schoolvakken" />
            </template>

            <template #onderwijsinstelling-data="{row}">
              <Opleiders :data="row.onderwijsinstellingen" />
            </template>


          </LazySearchResultsTable>
        </SearchResultsContainer>

        <div class="md:order-0 lg:order-1 lg:col-span-2">
          <SearchMetaContainer>
            <SearchMap 
              :data="[
                { key: 'regioloketten', data: data?.map.regioloketten?.hits || [] }, 
                // { key: 'opleidingen', data: data?.map.opleidingen?.hits || [] }
              ]"
            />
            <LazyLoketListCard 
              v-if="!regiolokettenAsSearchList && useContentTypes.regioloket"
              :data="data?.results.regioloketten?.hits || []" 
              :error="error" 
              :loading="isLoading" 
              :is-geo-search="isGeoSearch"  
              :base-length="3"
              class="mt-4"
            />
          </SearchMetaContainer>
        </div>
      </section>
    </div>
  </NuxtLayout>
</template>